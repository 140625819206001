<template>
<div class='card'>
  <h5 class='font-bold hr-line-dashed-bottom'>{{ t['250'] }}</h5>
  <div class='p-fluid grid hr-line-dashed-bottom mt-2'>
    <div class="field col-12 md:col-2">
      <span class="p-float-label">
        <InputText id="tidInput" type="text" :disabled='true' v-model='tid'/>
        <label for="tidInput" class='font-bold'>{{ t['466'] }}</label>
      </span>
    </div>
    <div class="field col-12 md:col-2">
      <!--   TODO: Not disabled and after rid and url -->
      <span class="p-float-label">
        <Dropdown id="midInput" v-model='mid' :options='tableModelList' optionLabel="mid"/>
        <label for="midInput" class='font-bold'>{{ t['473'] }}</label>
      </span>
    </div>
    <div class="field col-12 md:col-2">
      <span class="p-float-label">
        <InputText id="ridInput" type="text" :disabled='false' v-model='rid'/>
        <label for="ridInput" class='font-bold'>{{ t['493'] }}</label>
      </span>
    </div>
    <div class='md:col-5'></div>
  </div>
  <div class='p-fluid grid hr-line-dashed-bottom mt-2'>
    <div class="field col-12 md:col-3 mt-2">
      <span class="p-float-label">
        <InputNumber id="num1Input" showButtons v-model='number1Value' :min='minNum1' :max='maxNum1'
                     :minFractionDigits='3' :maxFractionDigits='3' :step='0.001' :disabled='!num1disabled'/>
        <label for="num1Input" v-bind:class="[!num1disabled ? 'font-light' : 'font-bold']">{{ t['475'] }}</label>
      </span>
    </div>
    <div class="field col-12 md:col-3 mt-2">
      <span class="p-float-label">
        <InputNumber id="num2Input" showButtons v-model='number2Value' :min='minNum2' :max='maxNum2'
                     :minFractionDigits='3' :maxFractionDigits='3' :step='0.001' :disabled='!num2disabled'/>
        <label for="num2Input" v-bind:class="[!num2disabled ? 'font-light' : 'font-bold']">{{ t['476'] }}</label>
      </span>
    </div>
    <div class="field col-12 md:col-3 mt-2">
      <span class="p-float-label">
        <InputNumber id="num3Input" showButtons v-model='number3Value' :min='minNum3' :max='maxNum3' :disabled='!num3disabled'/>
        <label for="num3Input" v-bind:class="[!num3disabled ? 'font-light' : 'font-bold']">{{ t['477'] }}</label>
      </span>
    </div>
    <div class="field col-12 md:col-3 mt-2">
      <span class="p-float-label">
        <InputNumber id="num4Input" showButtons v-model='number4Value' :min='minNum4' :max='maxNum4' :disabled='!num4disabled'/>
        <label for="num4Input" v-bind:class="[!num4disabled ? 'font-light' : 'font-bold']">{{ t['478'] }}</label>
      </span>
    </div>
    <div class="field col-12 md:col-3">
      <span class="p-float-label">
        <InputText id="var1Input" type="text" v-model='string1Value' :disabled='!string1disabled' @keyup='shortenString1()'/>
        <label for="var1Input" v-bind:class="[!string1disabled ? 'font-light' : 'font-bold']">{{ t['479'] }}</label>
      </span>
    </div>
    <div class="field col-12 md:col-3">
      <span class="p-float-label">
        <InputText id="var2Input" type="text" v-model='string2Value' :disabled='!string2disabled' @keyup='shortenString2()'/>
        <label for="var2Input" v-bind:class="[!string2disabled ? 'font-light' : 'font-bold']">{{ t['480'] }}</label>
      </span>
    </div>
    <div class="field col-12 md:col-6"></div>
    <div class="field col-12 md:col-3">
      <span class="p-float-label">
        <Calendar id="date1Input" v-model='date1Value' :showIcon="true" :minDate='new Date()' :dateFormat="t['dateFormat']" :showButtonBar='true' :disabled='!dat1disabled'/>
        <label for="date1Input" v-bind:class="[!dat1disabled ? 'font-light' : 'font-bold']">{{ t['481'] }}</label>
      </span>
    </div>
    <div class="field col-12 md:col-3">
      <span class="p-float-label">
        <Calendar id="date2Input" v-model='date2Value' :showIcon="true" :minDate='new Date()' :dateFormat="t['dateFormat']" :showButtonBar='true' :disabled='!dat2disabled'/>
        <label for="date2Input" v-bind:class="[!dat2disabled ? 'font-light' : 'font-bold']">{{ t['482'] }}</label>
      </span>
    </div>
  </div>
  <div class='p-fluid grid hr-line-dashed-bottom mt-2'>
    <div class="field col-12 hr-line-bottom mt-2">
      <span class="p-float-label">
        <Textarea id='commentInput' v-model='commentValue' :autoResize='true' style='width: 100%;'></Textarea>
        <label for="commentInput" class='font-bold'>{{ t['447'] }}</label>
      </span>
    </div>
  </div>
  <div class='mt-2'>
    <Button class='p-button-primary mr-2' @click='addParamRecord'>{{ t['87'] }}</Button>
    <Button class='p-button-outlined' @click="backToTable">{{ t['86'] }}</Button>
  </div>
</div>
</template>

<script>
import { useStore } from 'vuex';
import { usePrimeVue } from 'primevue/config';
import { computed } from 'vue';
import axios from 'axios';

export default {
  name: 'Parameters',
  setup() {
    const store = useStore()
    const primeVue = usePrimeVue()

    return {
      token: computed(() => store.state.bearerToken),
      t: primeVue.config.locale,
    }
  },
  data() {
    return {
      tid: this.$route.params.tid,
      mid: null,
      rid: null,
      tableModelList: null,
      model: null,
      record: null,
      number1Value: null,
      num1disabled: 0,
      minNum1: null,
      maxNum1: null,
      number2Value: null,
      num2disabled: 0,
      minNum2: null,
      maxNum2: null,
      number3Value: null,
      num3disabled: 0,
      minNum3: null,
      maxNum3: null,
      number4Value: null,
      num4disabled: 0,
      minNum4: null,
      maxNum4: null,
      string1Value: null,
      string1disabled: 0,
      minString1: null,
      maxString1: null,
      string2Value: null,
      string2disabled: 0,
      minString2: null,
      maxString2: null,
      date1Value: null,
      dat1disabled: 0,
      minDate1: null,
      maxDate1: null,
      date2Value: null,
      dat2disabled: 0,
      minDate2: null,
      maxDate2: null,
      commentValue: null,
    }
  },
  watch: {
    '$route'(to) {
      this.tid = to.params.tid
    },
    mid: function() {
        this.getModel()
    },
  },
  mounted() {
    this.getTableValues()
  },
  methods: {
      getTableValues() {
          axios
              .get( '/administration/param/table/' + this.tid + '/get',  {
                  headers: {
                      Authorization: this.token
                  }
              })
              .then( res => {
                  if(res.status === 200 && res.data.rmsList[0].retCode === 0){
                      this.tableModelList = res.data.retObj.paramModels
                  }else {
                      this.$toast.add({severity:'error', summary: 'Error', detail:res.data.rmsList[0].retMsg, life: 3000})
                  }
              })
              .catch(error => {
                  console.log("There was an error loading text ID list!")
                  console.log(error)
              })
      },
    getModel() {
      axios
        .get( 'administration/param/table/'+ this.tid + '/model/' + this.mid.mid + '/get', {
          headers: {
            Authorization: this.token
          }
        })
        .then( res => {
          if(res.status === 200 && res.data.rmsList[0].retCode === 0) {
            this.model = res.data.retObj
            this.num1disabled = this.model.mnum1
            this.num2disabled = this.model.mnum2
            this.num3disabled = this.model.mnum3
            this.num4disabled = this.model.mnum4
            this.string1disabled = this.model.mvac1
            this.string2disabled = this.model.mvac2
            this.dat1disabled = this.model.mdat1
            this.dat2disabled = this.model.mdat2
            this.minNum1 = this.model.lnum1
            this.maxNum1 = this.model.hnum1
            this.minNum2 = this.model.lnum2
            this.maxNum2 = this.model.hnum2
            this.minNum3 = this.model.lnum3
            this.maxNum3 = this.model.hnum3
            this.minNum4 = this.model.lnum4
            this.maxNum4 = this.model.hnum4
            this.minString1 = this.model.lvac1
            this.maxString1 = this.model.hvac1
            this.minString2 = this.model.lvac2
            this.maxString2 = this.model.hvac2
            this.minDate1 = this.model.ldat1
            this.maxDate1 = this.model.hdat1
            this.minDate2 = this.model.ldat2
            this.maxDate2 = this.model.hdat2
          } else {
            this.$toast.add({severity:'error', summary: 'Error', detail:res.data.rmsList[0].retMsg, life: 3000});
          }
        })
        .catch(error => {
          console.log("There was an error loading model!");
          console.log(error);
        })
    },
    addParamRecord() {
      axios
        .post('administration/param/table/'+ this.tid + '/record/add', {
          'tid' : Number(this.tid),
          'mid' : Number(this.mid.mid),
          'rid' : Number(this.rid),
          'num1': this.number1Value,
          'num2': this.number2Value,
          'num3': this.number3Value,
          'num4': this.number4Value,
          'vac1': this.string1Value,
          'vac2': this.string2Value,
          'dat1': this.date1Value,
          'dat2': this.date2Value,
          'comment': this.commentValue
        }, {
          headers: {
            Authorization: this.token
          }
        })
        .then(res => {
          if(res.status === 200 && res.data.rmsList[0].retCode === 0) {
            setTimeout(() => {
              this.$toast.add({severity:'success', summary: 'Success!', detail:res.data.rmsList[0].retMsg, life: 3000})
            }, 500)
            this.backToTable()
          }else {
            this.$toast.add({severity:'error', summary: 'Error', detail:res.data.rmsList[0].retMsg, life: 3000})
          }
        })
        .catch(error  => {
          console.log(error);
          this.backToTable()
        })
    },
    shortenString1() {
          this.maxString1 = 10000
          if(this.maxString1) {
              this.string1Value = (this.string1Value).slice(0,this.maxString1)
          }
    },
    shortenString2() {
        this.maxString2 = 10000
          if(this.maxString2) {
              this.string2Value = (this.string2Value).slice(0,this.maxString2)
          }

    },
    backToTable() {
      this.$router.replace('/editParamTable/' + this.tid)
    },
  }
};
</script>

<style scoped lang='scss'>

.hr-line-dashed-bottom {
  border-bottom: 1px dashed lightgray;
}

</style>